<template>
  <div>
    <div class="carousel-form">
      <el-input
        v-model="input"
        placeholder="按用户名/用户类别查找"
        @input="flite(input)"
        style="width: 300px;margin-right:20px"
      ></el-input>
      <!-- <el-button type="primary"  @click="addCarousel">新增</el-button> -->
    </div>
    <div class="carousel-table">
      <el-table :data="tableData" ref="tableData" border style="width: 100%;">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="img" label="用户头像" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.img" alt class="img-style" />
          </template>
        </el-table-column>
        <el-table-column prop="id" label="用户ID" width="100"></el-table-column>
        <el-table-column prop="loginName" label="登录名" width="100"></el-table-column>
        <el-table-column prop="name" label="用户名" width="100"></el-table-column>
        <el-table-column prop="IsAdmin" label="用户类别" width="100"></el-table-column>
        <el-table-column prop="phone" label="手机" width="200"></el-table-column>
        <el-table-column prop="email" label="邮箱" width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="integral" label="积分" width="100"></el-table-column>
        <el-table-column prop="Money" label="知识币" width="100"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <div v-if="scope.row.IsAdmin=='普通用户'?true:false">
              <el-button
                @click="modify(scope.row)"
                class="button-icon"
                type="text"
                size="small"
              >设为管理员</el-button>
              <el-button type="text" size="small" class="button-icon" @click="Delete( scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="position:relative;width:100%;height:30px;padding-top:20px">
      <el-pagination
        style="position:absolute;right:0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY ,QUERYED} from "@/services/dao.js";

export default {    
    data(){
        return {
            name:"",
            input:"",
           currentPage:1, // 当前页
           pageSize:5, // 每页显示条数
           total:1, // 总条数 根据接口获取
        tableData: [],
        table: []
        }
    },
    created(){
      this.display(this.pageSize,0);
      // this.display();

    },
    methods: {
      
     flite(input) {
       console.log('flite')
      let  that = this;
      //  filter  数组API    includes  字符串API  
      //  和数组中的 includes类似
     that.tableData = that.table.filter(Val => {
        if (
      
          Val.name.includes(that.input) ||
          Val.IsAdmin.includes(that.input)
        ) {
          that.tableData.push(Val);
          return that.tableData;
          //alert(1)
        }
      });
    },

    //初始化加载数据
    async display(limit,offest){
    //页面加载
      let data = await QUERY(
        "POST","", ' PCUsers (limit: '+limit+', offset: '+offest+') { img id loginName IsAdmin name phone email integral Money}   PCUsers_aggregate {  aggregate {   count   }  }'
      );
      console.log(data)
      // console.log("data.data.PCUsers_aggregate.aggregate.count: ",data.data.PCUsers_aggregate.aggregate.count);
      this.total=data.data.PCUsers_aggregate.aggregate.count;
      let that=this;
      that.tableData.splice(0, this.tableData.length);
      for (let i = 0; i < data.data.PCUsers.length; i++) {
        that.tableData.push(
          {
            id:data.data.PCUsers[i].id,
            img:this.api.LoginURL.concat(data.data.PCUsers[i].img),
            loginName:data.data.PCUsers[i].loginName,
            IsAdmin:data.data.PCUsers[i].IsAdmin==1?"管理员":"普通用户", 
            name:data.data.PCUsers[i].name, 
            phone:data.data.PCUsers[i].phone, 
            email:data.data.PCUsers[i].email, 
            integral:data.data.PCUsers[i].integral, 
            Money:data.data.PCUsers[i].Money, 
          }
        );
        // return that.tableData;
        //  this.total=data.data.PCUsers_aggregate.aggregate.count;
        //  this.$message.success(that.tableData);
        // console.log(this.tableData)
      } 
      ////给查询的赋值
      that.table = that.tableData;
        
      },
        //当前页
        handleCurrentChange(val){
            console.log("handleCurrentChange",val);
            this.currentPage=val;
            // 切换页码时，要获取每页显示的条数
            this.display(this.pageSize,this.pageSize*(this.currentPage-1));
        },
        //切换每页条数
        handleSizeChange(val){
            console.log("handleSizeChange",val)
            this.pageSize=val;
            this.display(this.pageSize,0);
           // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.currentPage=1 
        },
        handleClick(row) {
            console.log(row);
        },
      // async  addCarousel(){
      //       this.$router.push("/addthemesd");
      //   },

      async Delete(row){
          let  data=  await  DELETE("post","",'delete_PCUsers(where: {id: {_eq: '+row.id+'}}) {  affected_rows }');
          console.log(data)
          if(data.data.delete_PCUsers.affected_rows>0){
          // this.display() ;
          this.$message.success("删除成功!");
          this.display(this.pageSize,0);
          }
          // console.log(row)
      },
      async modify(row){
          let  data=  await  DELETE("post","",'update_PCUsers(where: {id: {_eq: '+row.id+'}},_set:{IsAdmin:'+1+'}) {  affected_rows }');
          console.log(data)
          if(data.data.update_PCUsers.affected_rows>0){
          // this.display() ;
          this.$message.success("更改成功!");
          this.display(this.pageSize,0);
        }
      }
    }
  
}
</script>

<style>
.carousel-table {
  margin-top: 20px;
  text-align: center;
}
.button-icon {
  width: 100px;
  height: 30px;
  text-align: center;
  margin-bottom: 5px;
}
.img-style{
  max-width: 100%;
  height: auto;
}
</style>